<template>
  <van-button size="small" type="primary" native-type="button" @click="buttonClickHandle" :disabled="timer != null">{{count > 0 ?  count + ' s后重发' : '获取验证码' }}</van-button>
</template>

<script>
const TIME_COUNT = 60;

export default {
  name: "SmsCodeButton",
  props:{
    value: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      count: 0,
      timer: null,
    };
  },
  methods: {
    buttonClickHandle() {
      if (!this.value) {
        return this.$notify({ type: 'danger', message: '请输入手机号'});
      }
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
        this.$emit("click");
      }
    },
  },
  destroyed() {
    this.count = 0;
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
};
</script>